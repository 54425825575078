// .composition {
//     position: relative;

//     &__photo {
//         width: 55%;
//         box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
//         border-radius: 2px;
//         position: absolute;
//         z-index: 10;
//         transition: all .2s;
//         outline-offset: 2rem;

//         @include respond(tab-port) {
//             float: left;
//             position: relative;
//             width: 33.33333333%;
//             box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
//         }

//         &--p1 {
//             left: 0;
//             top: -2rem;

//             @include respond(tab-port)  {
//                 top: 0;
//                 transform: scale(1.2);
//             }
//         }

//         &--p2 {
//             right: 0;
//             top: 2rem;

//             @include respond(tab-port)  {
//                 top: -1rem;
//                 transform: scale(1.3);
//                 z-index: 100;
//             }
//         }

//         &--p3 {
//             left: 20%;
//             top: 10rem;

//             @include respond(tab-port)  {
//                 top: 1rem;
//                 left: 0;
//                 transform: scale(1.1);
//             }
//         }

//         &:hover {
//             outline: 1.5rem solid $color-primary;
//             transform: scale(1.05) translateY(-.5rem);
//             box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
//             z-index: 20;
//         }

//     }

//     &:hover &__photo:not(:hover) {
//         transform: scale(.95);
//     }
//     //composition:hover composition__photo:not(:hover) {
// }

.composition {
  position: relative;

  &__photo {
      width: 55%;
      box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
      border-radius: 2px;
      position: absolute;
      z-index: 10;
      transition: all .2s;
      outline-offset: 0rem;

      @include respond(tab-port) {
          float: left;
          position: relative;
          width: 66.66666%;
          box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
      }
      @include respond(phone) {
        float:left;
        position:relative;
        width: 33.3333333%;
    }    
      &--p1 {
          left: 5%;
          top: 0rem;
          border-radius: 1.5rem;
          @include respond(iphone10) {
            top:-74rem;
            left: 5rem;
            transform: scale(0.8);
          }
          @include respond(iphone10-land)  {
              top: -10rem;
              transform: scale(.8);
              left: -5rem;
          }
          @include respond(iphone678)  {
            top: -66rem;
            transform: scale(.8);
            left: 5rem;
        }
          @include respond(phone) {
            top:-66rem;
            left: 6rem;
            transform: scale(1.5);
  
        }    
      }

      &--p2 {
          right: 0;
          top: 2rem;
          border-radius: 1.5rem;
          @include respond(iphone10)  {
            top: -68rem;
            transform: scale(.8);
            left: 10rem;
            z-index: 100;
        }
          @include respond(iphone10-land)  {
              top: 5rem;
              transform: scale(.8);
              left: 5rem;
              z-index: 100;
          }
          @include respond(iphone678)  {
            top: -65rem;
            transform: scale(.8);
            left: 15rem;
            z-index: 100;
        }
          @include respond(phone) {
            top: -62rem;
            left: 5rem;

            transform: scale(1.5);
  
        }    
      }

      &--p3 {
          left: 20%;
          top: 10rem;
          border-radius: 1.5rem;
          @include respond(iphone10)  {
            top: -72rem;
            left: 15rem;
            transform: scale(.8);
        }
          @include respond(iphone10-land)  {
              top: -55rem;
              left: 15rem;
              transform: scale(.8);
          }
          @include respond(iphone678)  {
            top: -68rem;
            left: 30rem;
            transform: scale(.8);
        }
          @include respond(phone) {
            top:-68rem;
            left:-5rem;
            transform: scale(1.5);
        }    
      }

      &:hover {
          outline: .5rem solid $gunmetal;
          background-color:$color-white;
          border-radius: 0rem;
          transform: scale(1.5) translateY(-.5rem);
          box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
          z-index: 120;
        
        // @include respond(phone) {

        //   transform: scale(2.0);
        // }    
      }

  }

  &:hover &__photo:not(:hover) {
      transform: scale(.95);
  }
  //composition:hover composition__photo:not(:hover) {
}
