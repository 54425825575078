@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


// MEDIA QUERY MANAGER
/*
320 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {

  @if $breakpoint == lowres {
    @media only screen and (max-height: 58em) and (min-width: 64.2em){@content};
  }
  @if $breakpoint == lowres-v {
    @media only screen and (max-height: 58em){@content};
  }
  @if $breakpoint == lowres-vx {
    @media only screen and (max-height: 58em){@content};
  }
  @if $breakpoint == lowres-h {
    @media only screen and (max-width: 64.2em){@content};
  }

  @if $breakpoint == lowres-vxxx{
    @media only screen and (max-width: 42em){@content};
  }
  @if $breakpoint == lowres-vxx {
    @media only screen and (max-width: 46em){@content};
  }

  @if $breakpoint == lowres-hx {
    @media only screen and (max-width: 42em){@content};
  }
  @if $breakpoint == lowres-hxx {
    @media only screen and (max-width: 37em){@content};
  }
  @if $breakpoint == lowres-hxxx {
    @media only screen and (max-width: 34.2em){@content};
  }

  @if $breakpoint == dpi1 {
    @media only screen and (-webkit-min-device-pixel-ratio:1.0)and (-webkit-max-device-pixel-ratio:1) { @content };
  }
  @if $breakpoint == dpi2 {
    @media only screen and (-webkit-min-device-pixel-ratio:1.1)and (-webkit-max-device-pixel-ratio:2) { @content };
  }
  @if $breakpoint == dpi3 {
    @media only screen and (-webkit-min-device-pixel-ratio:2.1)and (-webkit-max-device-pixel-ratio:3) { @content };
  }
  @if $breakpoint == dpi4 {
    @media only screen and (-webkit-min-device-pixel-ratio:3.1)and (-webkit-max-device-pixel-ratio:4) { @content }; 
  }
}

