// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
//$light-gray: rgba(219, 212, 211, 1);
// $background-color-gray: rgba(34, 51, 59, 1);
// $color-grey-dark-2: #999;
// $gunmetal: rgba(34, 51, 59, 1);
// $light-gray: rgba(219, 212, 211, 1);
// $color-white: #fff;
// $background-color-primary: $light-gray;
// $background-color-content: #fff;
// $default-font-size: 1.6rem;
// $color-black: #000;
// $color-grey-dark: #777;
// $tangerine: #f08700ff;
// $tangerine-dark: #cc7400; 


// .bg-nav {
//   background-color: $background-color-gray;
// }


// .font-sniglet {
//   font-family: 'sniglet', Arial, sans-serif;
// }




// footer {
//   height:4vh;
//   .navbar {
//     transform: translate(0%,5%);
//     .nav-item {
//       font-size:1rem;
//     }
//   }
// }

.img-thumbnail {
  max-width: 300px !important;
}

// .header {
//   height: 55vh;
//   z-index:10;
//   background: linear-gradient(
//       to bottom,
//       $gunmetal 21%,
//       $color-grey-dark-2, 0.8 75%);
//   background-size: cover;
//   background-position: top;
//   position: relative;

//   @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
//       -webkit-clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%);
//       clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%);
//       height: 65vh;
//   }

//   @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
//           only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
//           only screen and (min-width: 125em) {
//       background: linear-gradient(
//           to bottom,
//           $gunmetal 21%,
//           rgba($color-grey-dark-2, 0.8) 75%
//           );
//   }

//   @include respond(phone) {
//       -webkit-clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%);
//       clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%);
//       height:65vh;
//       background: linear-gradient(
//         to bottom,
//         $gunmetal 21%,
//         rgba($color-grey-dark-2, 0.8) 75%);
//   }

//   &__logo-box {
//       position: absolute;
//       top: 4rem;
//       left: 4rem;
//   }

//   &__logo {
//       height: 3.5rem;
//   }
  
//   &__text-box {
//       position: absolute;
//       z-index:5;
//       top: 48%;
//       width:75%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       text-align: center;

//       @include respond(phone) {
//         top:45%;
//         width:90%;
//     }      
//   }
// }

// body {
//   box-sizing: border-box;
//   padding: 3rem;
//   background-color: $background-color-primary !important;
//   @include respond(tab-port) {
//     padding: 0;
//   }
// }

// .heading-primary {
//   color: $color-white;
//   //text-transform: uppercase;

//   backface-visibility: hidden;
//   margin-bottom: 3rem;

//   &--main {
//       font-family: 'sniglet', Arial, sans-serif;
//       display: block;
//       font-size: 6rem;
//       font-weight: 400;
//       letter-spacing: 1rem;
//       margin-bottom: 2rem;
//       animation-name: moveInLeft;
//       animation-duration: 1s;
//       animation-timing-function: ease-out;
      
//       @include respond(phone) {
//           letter-spacing: 0rem;
//           font-size: 3.5rem;
//       }
//       /*
//       animation-delay: 3s;
//       animation-iteration-count: 3;
//       */
//   }
  
//   &--sub {
//       display: block;
//       font-size: 2rem;
//       font-weight: 700;
//       letter-spacing: .35rem;
//       margin-bottom: 1rem;
//       animation: moveInRight 1s ease-out;

//       @include respond(phone) {
//           letter-spacing: 0rem;
//       }
//   }
// }

// .heading-secondary {
//   font-size: 3.5rem;
//   text-transform: uppercase;
//   font-weight: 700;
//   display: inline-block;
//   background-image: linear-gradient(to right, $tangerine-dark, $tangerine, $tangerine-dark);
//   -webkit-background-clip: text;
//   color: transparent;
//   letter-spacing: .2rem;
//   transition: all .2s;

//   @include respond(tab-port) {
//       font-size: 3rem;
//   }

//   @include respond(phone) {
//       font-size: 2.5rem;
//   }

//   &:hover {
//      // transform: skewY(2deg) skewX(15deg) scale(1.1);
//       transform: scaleX(1.4) scaleY(1.6);
//       text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
//   }
// }

// html {
//   // This defines what 1rem is
//   font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

//   @include respond(tab-land) { // width < 1200?
//       font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
//   }

//   @include respond(tab-port) { // width < 900?
//       font-size: 50%; //1 rem = 8px, 8/16 = 50%
//   }
  
//   @include respond(big-desktop) {
//       font-size: 75%; //1rem = 12, 12/16
//   }
// }


// .button {
//   &,
//   &:link,
//   &:visited {
//       text-transform: uppercase;
//       text-decoration: none;
//       padding: 1.5rem 4rem;
//       display: inline-block;
//       border-radius: 10rem;
//       transition: all .2s;
//       position: relative;
//       font-size: $default-font-size;
//       color: $color-white;
//       //Change for the <button> element
//       border: none;
//       cursor: pointer;
//   }
  
//   &:hover {
//       transform: translateY(-3px);
//       box-shadow: 0 1rem 2rem rgba($color-black,.2);
//       color: $color-white !important;
//       font-weight:800;
//       letter-spacing:.25rem;
//       &::after {
//           transform: scaleX(1.4) scaleY(1.6);
//           opacity: 0;
//       }
//   }
  
//   &:active,
//   &:focus {
//       outline: none;
//       transform: translateY(-1px);
//       box-shadow: 0 .5rem 1rem rgba($color-black,.2);
//   }
  
//   &--white {
//       background-color: $color-white;
//       color: $color-grey-dark;

//       &::after {
//           background-color: $color-white;
//       }
//   }

//   &--green {
//       background-color: $gunmetal;
//       color: $color-white !important;

//       &::after {
//           background-color: $gunmetal;
//       }
//   }
  
//   &::after {
//       content: "";
//       display: inline-block;
//       height: 100%;
//       width: 100%;
//       border-radius: 10rem;
//       position: absolute;
//       top: 0;
//       left: 0;
//       z-index: -1;
//       transition: all .4s;
//   }
  
//   &--animated {
//       animation: moveInBottom .5s ease-out .75s;
//       animation-fill-mode: backwards;
//   }
// }

// .button-text {
//   &:link,
//   &:visited {
//       font-size: $default-font-size;
//       color: $color-white;
//       display: inline-block;
//       text-decoration: none;
//       border-bottom: 1px solid $gunmetal;
//       padding: 3px;
//       transition: all .2s;
//   }

//   &:hover {
//       background-color: $gunmetal;
//       color: $color-white;
//       box-shadow: 0 1rem 2rem rgba($color-black, .15);
//       transform: translateY(-2px);
//   }

//   &:active {
//       box-shadow: 0 .5rem 1rem rgba($color-black, .15);
//       transform: translateY(0);
//   }
// }

// .u-center-text { text-align: center !important; }

// .u-margin-bottom-small { margin-bottom: 1.5rem !important; }
// .u-margin-bottom-medium {
//     margin-bottom: 4rem !important;

//     @include respond(tab-port) {
//         margin-bottom: 3rem !important;
//     }
// }

// .u-margin-bottom-big { 
//     margin-bottom: 8rem !important;

//     @include respond(tab-port) {
//         margin-bottom: 5rem !important;
//     }
// }

// .u-margin-top-big { margin-top: 8rem !important; }
// .u-margin-top-huge { margin-top: 10rem !important; }

// .section-look {
//   background-color: $color-white;
//   padding: 25rem 0;
//   margin-top: -30vh;

//   @include respond(tab-port) {
//       padding: 30rem 0;
//   }
//   @include respond(phone) {
//     padding:22rem 0;
//   }    
// }

// .composition {
//   position: relative;

//   &__photo {
//       width: 55%;
//       box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
//       border-radius: 2px;
//       position: absolute;
//       z-index: 10;
//       transition: all .2s;
//       outline-offset: 0rem;

//       @include respond(tab-port) {
//           float: left;
//           position: relative;
//           width: 66.66666%;
//           box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
//       }
//       @include respond(phone) {
//         float:left;
//         position:relative;
//         width: 33.3333333%;
//     }    
//       &--p1 {
//           left: 5%;
//           top: 0rem;
//           border-radius: 1.5rem;
//           @include respond(tab-port)  {
//               top: 2.5rem;
//               transform: scale(1.15);
//               left: 10rem;
//           }
//           @include respond(phone) {
//             top:18rem;
//             transform: scale(2.5);
  
//         }    
//       }

//       &--p2 {
//           right: 0;
//           top: 2rem;
//           border-radius: 1.5rem;
//           @include respond(tab-port)  {
//               top: 26rem;
//               transform: scale(1.15);
//               left: 10rem;
//               z-index: 100;
//           }
//           @include respond(phone) {
//             top: 35rem;
//             left:2rem;
//             transform: scale(2.5);
  
//         }    
//       }

//       &--p3 {
//           left: 20%;
//           top: 10rem;
//           border-radius: 1.5rem;
//           @include respond(tab-port)  {
//               top: -15rem;
//               left: 10rem;
//               transform: scale(1.15);
//           }
//           @include respond(phone) {
//             top:3rem;
//             left:-5rem;
//             transform: scale(2.5);
//         }    
//       }

//       &:hover {
//           outline: .5rem solid $gunmetal;
//           background-color:$color-white;
//           border-radius: 0rem;
//           transform: scale(1.5) translateY(-.5rem);
//           box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
//           z-index: 20;
        
//         @include respond(phone) {

//           transform: scale(2.5);
//         }    
//       }

//   }

//   &:hover &__photo:not(:hover) {
//       transform: scale(.95);
//   }
//   //composition:hover composition__photo:not(:hover) {
// }

// .row {
//   max-width: $grid-width;
//   margin: 0 auto;
//   flex-wrap: nowrap !important;

//   &:not(:last-child) {
//       margin-bottom: $gutter-vertical;

//       @include respond(tab-port) {
//           margin-bottom: $gutter-vertical-small;
//           max-width:50rem;
//       }
//   }
  
//   @include respond(tab-port) {
//       max-width: 72rem;
//       padding: 0 3rem;
//   }

//   @include clearfix;

//   [class^="col-"] {
//       float: left;

//       &:not(:last-child) {
//           margin-right: $gutter-horizontal;

//           @include respond(tab-port) {
//               margin-right: 0;
//               margin-bottom: $gutter-vertical-small;
//           }
//       }

//       @include respond(tab-port) {
//           width: 50% !important;
//       }
//       @include respond(phone) {
//         width: 50% !important;
//     }      
//   }

//   .col-1-of-2 {
//       width: calc((100% - #{$gutter-horizontal}) / 2);
      
//   }

//   .col-1-of-3 {
//       width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
//   }

//   .col-2-of-3 {
//       width: calc(2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
//   }

//   .col-1-of-4 {
//       width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
//   }

//   .col-2-of-4 {
//       width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
//   }

//   .col-3-of-4 {
//       width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
//   }

// }

// @keyframes moveInLeft {
//   0% {
//       opacity: 0;
//       transform: translateX(-10rem);
//   }

//   80% {
//       transform: translateX(1rem);
//   }

//   100% {
//       opacity: 1;
//       transform: translate(0);
//   }
// }

// @keyframes moveInRight {
//   0% {
//       opacity: 0;
//       transform: translateX(10rem);
//   }

//   80% {
//       transform: translateX(-1rem);
//   }

//   100% {
//       opacity: 1;
//       transform: translate(0);
//   }
// }

// @keyframes moveInBottom {
//   0% {
//       opacity: 0;
//       transform: translateY(3rem);
//   }

//   100% {
//       opacity: 1;
//       transform: translate(0);
//   }
// }

// .heading-tertiary {
//   font-size: $default-font-size;
//   font-weight: 700;
//   text-transform: uppercase;
//   @include respond(tab-port)  {
//     font-size: $default-font-size+1;
//   }
//   @include respond(phone) {
//     font-size: $default-font-size+.2;
//   }   
// }

// .paragraph {
//   font-size: $default-font-size;
//   @include respond(tab-port)  {
//     font-size: $default-font-size + .5;
//   }
//   @include respond(phone) {
//     font-size: $default-font-size;
//   }   
//   &:not(:last-child) {
//       margin-bottom: 3rem;
//   }
// }