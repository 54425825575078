// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
$rufous: rgba(155, 41, 21, 1);
$gunmetal: rgba(34, 51, 59, 1);
$light-gray: rgba(219, 212, 211, 1);
$medium-sea-green: rgba(105, 181, 120, 1);
$tangerine: rgba(240, 135, 0, 1);
$tangerine-dark: rgba(204, 116, 0, 1);
$background-color-gray: rgba(34, 51, 59, 1);
$background-color-primary: $light-gray;
$background-color-content: #fff;
// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

