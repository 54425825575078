*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
    // @include respond(v-md) {
    //   font-size:50%;
    // }
    @include respond(lowres) {
      font-size:50%;
    }
    @include respond(lowres-v) {
      font-size:40%;
    }
    @include respond(lowres-h) {
      font-size:40%;
    }
    @include respond(lowres-hx) {
      font-size:36%;
    }
    @include respond(lowres-hxx) {
      font-size:34%;
    }
    // @include respond(v-sm) {
    //   font-size:40%;
    // }
    // @include respond(h-sm) {
    //   font-size:40% !important;
    // }
    // @include respond(v-smx) {
    //   font-size:30%;
    // }
    // @include respond(h-smx) {
    //   font-size:30% !important;
    // }
    // @include respond(v-smxx) {
    //   font-size:20%;
    // }
    // @include respond(h-smxx) {
    //   font-size:20% !important;
    // }
    @include respond(mdpi) { // width < 1200?
        font-size: 40%; //1 rem = 9px, 9/16 = 50%
    }
}

body {
    box-sizing: border-box;
    padding: 0rem;
    background-color: $color-white!important;
}

::selection {
    background-color: $color-primary;
    color: $color-white;
}