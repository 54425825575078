.btn {
    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all .2s;
        position: relative;
        font-size: $default-font-size;

        //Change for the <button> element
        border: none;
        cursor: pointer;
    }
    
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba($color-black,.2);

        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
        }
    }
    
    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba($color-black,.2);
    }
    
    &--white {
        background-color: $color-white;
        color: $color-grey-dark;

        &::after {
            background-color: $color-white;
        }
    }

    &--green {
        background-color: $color-primary;
        color: $color-white;

        &::after {
            background-color: $color-primary;
        }
    }
    
    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
    
    &--animated {
        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;
    }
}

.btn-text {
    &:link,
    &:visited {
        font-size: $default-font-size;
        color: $gunmetal;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid $gunmetal;
        padding: 3px;
        transition: all .2s;
    }

    &:hover {
        background-color: $gunmetal;
        color: $color-white;
        box-shadow: 0 1rem 2rem rgba($color-black, .15);
        transform: translateY(-2px);
    }

    &:active {
        box-shadow: 0 .5rem 1rem rgba($color-black, .15);
        transform: translateY(0);
    }
    &--white {
      &:link,
      &:visited {
          font-size: $default-font-size;
          color: $color-white;
          display: inline-block;
          text-decoration: none;
          border-bottom: 1px solid $color-white;
          padding: 6px;
          transition: all .2s;
      }
  
      &:hover {
          background-color: $gunmetal;
          color: $color-white;
          box-shadow: 0 1rem 2rem rgba($color-black, .15);
          transform: translateY(-2px);
      }
  
      &:active {
          box-shadow: 0 .5rem 1rem rgba($color-black, .15);
          transform: translateY(0);
      }
    }
}

.button {
  &,
  &:link,
  &:visited {
      text-transform: uppercase;
      text-decoration: none;
      padding: 1.5rem 4rem;
      display: inline-block;
      border-radius: 10rem;
      transition: all .2s;
      position: relative;
      font-size: $default-font-size;
      color: $color-white;
      //Change for the <button> element
      border: none;
      cursor: pointer;
  }
  
  &:hover {
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba($color-black,.2);
      color: $color-white !important;
      font-weight:800;
      letter-spacing:.25rem;
      &::after {
          transform: scaleX(1.4) scaleY(1.6);
          opacity: 0;
      }
  }
  
  &:active,
  &:focus {
      outline: none;
      transform: translateY(-1px);
      box-shadow: 0 .5rem 1rem rgba($color-black,.2);
  }
  
  &--white {
      background-color: $color-white;
      color: $color-grey-dark;

      &::after {
          background-color: $color-white;
      }
  }

  &--gunmetal {
      background-color: $gunmetal;
      color: $color-white !important;

      &::after {
          background-color: $gunmetal;
      }
  }
  
  &::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 10rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all .4s;
  }
  
  &--animated {
      animation: moveInBottom .5s ease-out .75s;
      animation-fill-mode: backwards;
  }
}
.button-text {
  &:link,
  &:visited {
      font-size: $default-font-size;
      color: $color-white;
      display: inline-block;
      text-decoration: none;
      border-bottom: 1px solid $gunmetal;
      padding: 3px;
      transition: all .2s;
  }
  &:hover {
    background-color: $gunmetal;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, .15);
    transform: translateY(-2px);
  }

  &:active {
      box-shadow: 0 .5rem 1rem rgba($color-black, .15);
      transform: translateY(0);
  }
}