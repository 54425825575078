$font-stack: Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;

@font-face {
  font-family: 'sniglet';
  src: url('css/fonts/sniglet-regular-webfont.woff2') format('woff2'),
       url('css/fonts/sniglet-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.facebook {
  width: 64px;
  height: 64px;
  display: inline-block;
  background-position: 0 0px !important;

}

.instagram {
  width: 64px;
  height: 64px;
  display: inline-block;
  background-position: 0 -64px !important;
}

.twitter {
  width: 64px;
  height: 64px;
  display: inline-block;
  background-position: 0 -128px !important;
}

.sprite {
  background: url("./static/social-icons.png") no-repeat;
  zoom: .5;
}