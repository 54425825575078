// //hdpi
// $hdpi_header_height: 70vh;

// //mdpi
// $mdpi_height: 65vh;
// $mdpi_header__text-box_top: 45%;
// $mdpi_header__text-box_width: 80%;
// $mdpi_header__text-box_left: 50%;  

// //iphone10
// $iphone10_height: 55vh;
// $iphone10_header__text-box_top: 50%;
// $iphone10_header__text-box_width: 90%;
// $iphone10_header__text-box_left: 50%;  

$header__text-box_top: 48%;
$header__text-box_width: 75%;
$header__text-box_left: 50%;
$header_height: 45vh;
$header_clip_height: $header_height - 20vh;

.header {
  margin-top: -1vh;
  width: 100%;
  height: $header_height;

  z-index:10;
  background: linear-gradient(
    to bottom,
    $gunmetal 25%,
    rgba($color-grey-dark-2, 1) 100%,
      );
  background-size: cover;
  background-position: top;
  position: relative;

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% $header_clip_height, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% $header_clip_height, 0 100%);
    background: linear-gradient(
      to bottom,
      $gunmetal 21%,
      rgba($color-grey-dark-2, 1) 100%);
  }

  @include respond(lowres) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 25vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 25vh, 0 100%);
  //  height: 40vh;
  }
  @include respond(lowres-v) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 20vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 20vh, 0 100%);
    height: 35vh;
  }
  @include respond(lowres-h) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 20vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 20vh, 0 100%);
    height: 35vh;
  }
  @include respond(lowres-hxxx) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 25vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 25vh, 0 100%);
    height: 40vh;
  }
  @include respond(lowres-vxx) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 30vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 30vh, 0 100%);
    height: 50vh;
  }
  @include respond(lowres-vxxx) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 30vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 30vh, 0 100%);
    height: 45vh;
  }
  &__logo-box {
      position: absolute;
      top: 4rem;
      left: 4rem;
  }

  &__logo {
      height: 3.5rem;
  }
  
  &__text-box {
      position: relative;
      z-index:5;
      width: 100%;
       left: $header__text-box_left;
      transform: translate(-50%, 0%);
      text-align: center;
  }
}