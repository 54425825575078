body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    /*font-size: 16px;*/
    line-height: 1.7;
    color: $color-grey-dark;
}

// .heading-primary {
//     color: $color-white;
//     text-transform: uppercase;

//     backface-visibility: hidden;
//     margin-bottom: 6rem;

//     &--main {
//         display: block;
//         font-size: 6rem;
//         font-weight: 400;
//         letter-spacing: 3.5rem;
    
//         animation-name: moveInLeft;
//         animation-duration: 1s;
//         animation-timing-function: ease-out;
        
//         @include respond(phone) {
//             letter-spacing: 1rem;
//             font-family: 5rem;
//         }
//         /*
//         animation-delay: 3s;
//         animation-iteration-count: 3;
//         */
//     }
    
//     &--sub {
//         display: block;
//         font-size: 2rem;
//         font-weight: 700;
//         letter-spacing: 1.75rem;
//         animation: moveInRight 1s ease-out;

//         @include respond(phone) {
//             letter-spacing: .5rem;
//         }
//     }
// }

// .heading-secondary {
//     font-size: 3.5rem;
//     text-transform: uppercase;
//     font-weight: 700;
//     display: inline-block;
//     background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
//     -webkit-background-clip: text;
//     color: transparent;
//     letter-spacing: .2rem;
//     transition: all .2s;

//     @include respond(tab-port) {
//         font-size: 3rem;
//     }

//     @include respond(phone) {
//         font-size: 2.5rem;
//     }

//     &:hover {
//         transform: skewY(2deg) skewX(15deg) scale(1.1);
//         text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
//     }
// }

// .heading-tertiary {
//     font-size: $default-font-size;
//     font-weight: 700;
//     text-transform: uppercase;
// }

// .paragraph {
//     font-size: $default-font-size;

//     &:not(:last-child) {
//         margin-bottom: 3rem;
//     }
// }


.heading-primary {
  color: $color-white;
  //text-transform: uppercase;

  backface-visibility: hidden;
  margin-bottom: 3rem;

  &--main {
      font-family: 'sniglet', Arial, sans-serif;
      display: block;
      font-size: 6rem;
      font-weight: 400;
      letter-spacing: 1rem;
      margin-bottom: 2rem;
      animation-name: moveInLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      
      @include respond(phone) {
          letter-spacing: 0rem;
          //font-size: 3.5rem;
      }
      @include respond(iphone10-land) {
        letter-spacing: 0rem;
       // font-size:4.5rem;
      }
      /*
      animation-delay: 3s;
      animation-iteration-count: 3;
      */
  }
  
  &--sub {
      display: block;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: .35rem;
      margin-bottom: 1rem;
      animation: moveInRight 1s ease-out;

      @include respond(phone) {
          letter-spacing: 0rem;
      }
      @include respond(iphone10-land) {
        letter-spacing: 0rem;
      }
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, $tangerine-dark, $tangerine, $tangerine-dark);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;

  // @include respond(tab-port) {
  //     font-size: 3rem;
  // }

  // @include respond(phone) {
  //     font-size: 2.5rem;
  // }

  &:hover {
     // transform: skewY(2deg) skewX(15deg) scale(1.1);
      transform: scaleX(1.4) scaleY(1.6);
      text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
  // @include respond(tab-port)  {
  //   font-size: $default-font-size+1;
  // }
  // @include respond(phone) {
  //   font-size: $default-font-size+.2;
  // }   
}

.paragraph {
  font-size: $default-font-size;
  // @include respond(tab-port)  {
  //   font-size: $default-font-size + .5;
  // }
  // @include respond(phone) {
  //   font-size: $default-font-size;
  // }   
  &:not(:last-child) {
      margin-bottom: 3rem;
  }
}

.bg-nav {
  background-color: $background-color-gray;
}