.navbar {
  display: flex;
  .nav-item {
    text-align:right;
    font-size:2rem;
  }
  z-index: 10;
  .navbar-collapse {
    padding-top:8px;
  }
  .logo {
    max-width:372px !important;
    max-height: 77px !important;
    @include respond(lowres) {
      max-width: 250px !important;
    }
    @include respond(lowres-v) {
      max-width: 250px !important;
    }
  }
  .navbar-toggler-icon {
    width: 4rem;
    height: 4rem;
    // @include respond(lowres) {
    //   width: 4rem;
    //   height: 3rem;
    // }
    // @include respond(lowres-v) {
    //   width: 3rem;
    //   height: 3rem;
    // }  
  }
}
